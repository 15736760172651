import { leftPad } from '@/lib/leftPad'
import { customAlphabet } from 'nanoid'

export const shortid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 6)

export function randomToolCallId() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const length = 24
  let result = ''
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    result += characters[randomIndex]
  }
  return `call_${result}`
}

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export function getRandomNumber(min: number, max: number): number {
  return Math.random() * (max - min) + min
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min: number, max: number): number {
  const minCleaned = Math.ceil(min)
  const maxCleaned = Math.floor(max)
  return Math.floor(Math.random() * (maxCleaned - minCleaned + 1)) + minCleaned
}

export function randomPhone(n = getRandomInt(0, 9999999)) {
  return `+1555${leftPad(`${n}`, 7)}`
}

export function getRandomNumberString(length: number): string {
  const nanoid = customAlphabet('0123456789')
  return nanoid(length)
}

export function getRandomCode(length: number): string {
  const nanoid = customAlphabet('0123456789')
  return nanoid(length)
}

export function getRandomPassword(): string {
  const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*()_+-=')
  return nanoid(STRONG_PASSWORD_LENGTH)
}

export function getRandomColor(): string {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`
}

export const STRONG_PASSWORD_LENGTH = 15
