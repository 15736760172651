// https://jsonresume.org/
type ResumeSchema = {
  meta: {
    theme: 'elegant' | 'modern'
    voice: 'alloy' | 'shimmer' | 'echo'
  }
  [key: string]: any
}

export const resumes: ResumeSchema[] = [
  {
    meta: {
      theme: 'modern',
      voice: 'alloy',
    },
    basics: {
      name: 'Thomas Davis',
      label: 'Web Developer',
      image: 'https://avatars0.githubusercontent.com/u/416209?s=460&u=38f220a2c9c658141804f881c334c594eb1642ac&v=4',
      summary:
        "I'm a full stack web developer who can build apps from the ground up. I've worked mostly at startups so I am used to wearing many hats. I am a very product focussed developer who priotizes user feedback first and foremost. I'm generally very flexible when investigating new roles. ",
      website: 'https://lordajax.com',
      email: 'thomasalwyndavis@gmail.com',
      location: {
        city: 'Melbourne',
        countryCode: 'AU',
      },
      profiles: [
        {
          username: 'ajaxdavis',
          url: 'https://twitter.com/ajaxdavis',
          network: 'twitter',
        },
        {
          url: 'https://github.com/thomasdavis',
          username: 'thomasdavis',
          network: 'github',
        },
      ],
    },
    education: [
      {
        endDate: '2009-12-12',
        startDate: '2008-02-12',
        area: 'Software Engineering (incomplete)',
        studyType: 'Bachelors',
        institution: 'The University of Queensland',
      },
    ],
    references: [
      {
        reference:
          'Thomas was hired as a lead developer and, upon the leaving of our co-founder took over as CTO of Earbits. Thomas is, hands down, one of those A Players you hear of companies dying to hire. He is incredibly smart, not just at code but about everything from classical music to Chinese language and culture. Thomas is great to work with and, as a well established contributor to open source projects and several successful ventures, commands the respect of engineers at all levels. I would suggest doing anything you can to have him on your team.',
        name: 'Joey Flores, Co-founder and CEO of Earbits, Inc.',
      },
      {
        reference:
          "I've had the great pleasure of working with Thomas for the past three years at Earbits, and on a few side projects. Two years ago our CTO left on a moment's notice, Thomas saved our company by quickly stepping up to fill this role. He has been with our company through thick and thin and made serious personal sacrifices in order to help the company during tough times. He is a phenomenal hacker and a true team player. Highly recommended!",
        name: 'Yotam Rosenbaum, SVP of Operations, Earbits, Inc.',
      },
      {
        reference:
          'Thomas is an extremely talented engineer with a very broad range of skills and experience. From being a thought leader in the front-end community via backbonetutorials.com and cdnjs.com, to designing and implementing the API for cdnjs.com, working with Thomas has been fantastic learning experience. Thomas is truly a full stack develop, and his work output is incredible. If there is any opportunity to work with Thomas, I take it. He is the definition of an A player.',
        name: 'Ryan Kirkman, Senior Software Engineer at Nerdwallet',
      },
      {
        reference:
          'On Thomas Davis... Hire this guy. Do not be fooled. Incredibly capable and fast. Plays well with others. Unbelievable at front-end work end programming but that is just the start. Visionary. Hire him before I do.',
        name: 'Greg Davis',
      },
    ],
    skills: [
      {
        keywords: [
          'HTML / JSX',
          'SCSS / CSS / BEM / Styled Components',
          'Javascript / Typescript',
          'React / Next',
          'Redux / Apollo',
        ],
        level: 'Senior',
        name: 'Frontend',
      },
      {
        keywords: ['Node', 'Ruby', 'Python', 'Postgres', 'Redis', 'Serverless'],
        level: 'Senior',
        name: 'Backend',
      },
      {
        keywords: ['AWS', 'G Cloud', 'Heroku', 'Caching'],
        level: 'Senior',
        name: 'Devops',
      },
    ],
    awards: [
      {
        title: 'Defender of the Internet',
        awarder: 'Fight For The Future',
      },
    ],
    work: [
      {
        summary:
          'Tokenized is a Bitcoin wallet for issuing, managing and trading digital tokens. I built out the front end which was packaged as an electron app. It was a difficult frontend to build because we store the users keys locally and used them to sign transactions and contracts.',
        website: 'https://tokenized.com/',
        name: 'Tokenized',
        location: 'Melbourne',
        position: 'Senior Javascript Developer',
        startDate: '2020-05-05',
        highlights: ['React', 'Redux', 'SCSS', 'Product'],
      },
      {
        summary:
          'Blockbid is an Australian crypto currency exchange. I started off on the frontend but eventually became lead tech and worked on every moving piece of the exchange. I really enjoyed working with liquidity providers and connecting their platforms to help us achieve liquid markets.',
        website: 'https://platform.blockbid.io',
        name: 'Blockbid',
        location: 'Melbourne',
        position: 'Senior Javascript Developer',
        startDate: '2018-03-01',
        endDate: '2020-01-01',
        highlights: [
          'React, Apollo, Styled Components',
          'Node.js / Rails',
          'Docker / Heroku / GCP',
          'Used Figma for design and UX work',
          'Optimizing markets with tens of millions of rows using SQL.',
        ],
      },
      {
        highlights: [
          'Worked with Postgres, Redis and Dynamodb for storage.',
          'Hosted on a mixture of Heroku Apps and EC2 servers.',
          'Caching by Fastly and Cloudflare',
          'Hybrid app supported on all platforms',
        ],
        summary:
          'Built a very large and complex React / Redux application. It works on all platforms and has IOS/Android builds due to it being a PWA. (wrapped it in React Native though only implementing a WebView)',
        website: 'https://listium.com',
        pinned: true,
        name: 'Listium',
        position: 'Developer',
        startDate: '2016-01-01',
        endDate: '2018-01-01',
      },
      {
        highlights: [
          'Millions of sites use the CDN in production',
          "Larger market share than Yahoo's, Microsoft's and Google's javascript content distribution networks",
          'We serve hundreds of billions request a month',
          'Contains over 3000 popular Javascript libraries',
          'Millions of developers visit the site per year',
        ],
        pinned: true,
        summary:
          "Following Google's CDN for jQuery, we decided to start a CDN for the less popular Javascript frameworks. The CDN is community moderated and open source on GitHub. We secured a partnership with Cloudflare who now supports the infrastructure.",
        website: 'http://www.cdnjs.com',
        name: 'Cdnjs',
        position: 'Co-Founder',
        startDate: '2011-01-08',
      },
      {
        highlights: [
          'Developed new tools for contacting congress',
          'Brainstormed campaign ideas to raise maximum awareness about issues',
          'Lots of social networking integration',
        ],
        summary:
          "Hired to take EFF's campaigning to the next level by building an action centre in Ruby on Rails. The action centre is built around some large open source tools that lower the barrier to entry when contacting congress.",
        website: 'http://www.eff.org',
        name: 'Electronic Frontier Foundation',
        position: 'Developer',
        startDate: '2014-04-01',
        endDate: '2016-01-01',
      },
      {
        highlights: [
          'Managed a small team of developers and designers',
          'Built the entire frontend application with Backbone.js',
          'Transferred all of the infrastructure from Heroku to AWS',
        ],
        summary:
          'Started off as a front end developer but took on the role of CTO in early 2013. The application frontend is built with Javascript and organized as a single page application that talks to a collection of Rails web servers which are connected to MongoDB.',
        website: 'http://www.earbits.com',
        name: 'Earbits',
        position: 'CTO',
        endDate: '2015-01-09',
        startDate: '2013-03-08',
      },
      {
        highlights: [
          'This resume is built with JSON Resume',
          'Over 3000 stars on Github',
          'Community developed themes',
          'Tens of thousands of users',
        ],
        summary:
          "JSON Resume is a community driven open source initiative to create a JSON based standard for resumes. There is no reason why there can't be a common standard for writing a resume that can be extended with an ecosystem of open source tools.",
        website: 'http://jsonresume.org',
        pinned: true,
        name: 'JSON Resume',
        position: 'Founder',
        startDate: '2014-04-01',
      },
      {
        highlights: [
          'Generated  37,000,000 banner views',
          '100, 000 phone calls to congress',
          '500, 000 emails',
          '250, 000 signatures',
        ],
        summary:
          'Worked on many politically charged campaigns against mass surveillance. Not only technically challenging work but also a lot of networking and getting my hands dirty with politics.    Our biggest project was "TheDayWeFightBack"..',
        website: 'http://www.taskforce.is',
        name: 'Taskforce.is',
        position: 'Developer',
        startDate: '2013-06-01',
        endDate: '2016-01-01',
      },
      {
        highlights: ['The site and blog combined have managed to receive over 200,000 visitors in 2014.'],
        summary:
          'An international directory of civilian drone / UAV operators for hire. Services include aerial photography, aerial video, mapping, surveying, precision agriculture, real estate photography, remote inspections and infrared imaging. Our plan is to be the place to go when looking for UAV/Drone services. The website is built in Backbone.js and API is built with Node.js and Postgres. ',
        website: 'http://www.dronehire.org',
        name: 'Drone Hire',
        position: 'Co-Founder',
        startDate: '2013-01-01',
      },
      {
        highlights: ['Over two million unique visitors a year', '25,000+ ebook downloads', '300,000+ Youtube views'],
        summary:
          'I write tutorials and blog post regarding the popular Javascript framework Backbone.js. The tutorials cover a range of topics regarding front end development aimed at beginners, experts and anyone in between.',
        website: 'http://backbonetutorials.com',
        name: 'BackboneTutorials.com',
        position: 'Founder',
        startDate: '2011-01-01',
        endDate: '2014-01-01',
      },
      {
        summary:
          'Ephox is a worldwide company who is heavily involved with the development of TinyMce and enterprise editors. My primary role included building front-end widgets and applications. Worked on a major product using Backbone.js as a base. Heavily involved in UI/UX design and wire-framing. Also spend a lot of time designing API specifications and documentation.',
        website: 'http://ephox.com',
        name: 'Ephox',
        position: 'Front-end Developer',
        endDate: '2012-06-01',
        startDate: '2011-01-01',
      },
    ],
    interests: [
      {
        name: 'Gardening',
      },
      {
        name: 'Music / Jamming',
      },
      {
        name: 'Reading / Writing',
      },
      {
        name: 'Open Source',
      },
    ],
  },

  {
    meta: {
      theme: 'modern',
      voice: 'shimmer',
    },
    basics: {
      name: 'John Smith',
      label: 'Full Stack Developer',
      image: 'https://example.com/johnsmith.jpg',
      summary:
        "I'm an experienced full stack developer with 7+ years of expertise in designing, developing, and deploying web applications. I'm passionate about building scalable systems and have a strong interest in cloud computing and software architecture.",
      website: 'https://johnsmith.dev',
      email: 'john.smith@gmail.com',
      location: {
        city: 'New York',
        countryCode: 'US',
      },
      profiles: [
        {
          username: 'johnsmith',
          url: 'https://twitter.com/johnsmith',
          network: 'twitter',
        },
        {
          url: 'https://github.com/johnsmith',
          username: 'johnsmith',
          network: 'github',
        },
      ],
    },
    education: [
      {
        endDate: '2014-06-01',
        startDate: '2010-09-01',
        area: 'Software Engineering',
        studyType: 'Bachelors',
        institution: 'Massachusetts Institute of Technology',
      },
    ],
    references: [
      {
        reference:
          'John is a highly talented developer who has an impressive depth of knowledge in software engineering. He always brings enthusiasm and creativity to projects, making him a pleasure to work with.',
        name: 'Mary Johnson, VP of Engineering at WebWorks',
      },
      {
        reference:
          "John's ability to solve complex problems and his excellent communication skills make him an invaluable team member. He takes ownership of his work and consistently delivers high-quality results.",
        name: 'Alex Brown, CTO at CloudInnovate',
      },
    ],
    skills: [
      {
        keywords: ['JavaScript', 'React', 'Vue.js', 'TypeScript', 'HTML/CSS'],
        level: 'Advanced',
        name: 'Frontend Development',
      },
      {
        keywords: ['Node.js', 'Express.js', 'GraphQL', 'MongoDB', 'SQL'],
        level: 'Advanced',
        name: 'Backend Development',
      },
      {
        keywords: ['AWS', 'Docker', 'Kubernetes', 'Terraform'],
        level: 'Intermediate',
        name: 'Cloud & DevOps',
      },
    ],
    awards: [
      {
        title: 'Best Developer Award',
        awarder: 'Tech Company Annual Meet',
      },
    ],
    work: [
      {
        summary:
          'Led the development of multiple features for a social media platform, scaling it to millions of users. Worked closely with product teams to iterate and improve the user experience.',
        website: 'https://socialmedia.com',
        name: 'SocialMedia Co.',
        location: 'New York',
        position: 'Lead Full Stack Developer',
        startDate: '2019-03-01',
        highlights: [
          'Designed and implemented a microservices architecture',
          'Improved site performance leading to a 40% reduction in load times',
          'Mentored junior developers on best practices',
        ],
      },
      {
        summary:
          'Developed an internal dashboard for data analytics using modern front-end technologies. Integrated various data sources to provide valuable insights to stakeholders.',
        website: 'https://dataanalytics.com',
        name: 'DataAnalytics Inc.',
        location: 'New York',
        position: 'Full Stack Developer',
        startDate: '2016-07-01',
        endDate: '2019-02-01',
        highlights: [
          'Implemented interactive charts and data visualization using D3.js',
          'Developed secure authentication and role-based authorization features',
          'Worked in an agile environment, delivering features in sprints',
        ],
      },
      {
        summary:
          'Worked as a backend developer on a project management SaaS platform. Implemented REST APIs and handled integrations with external services.',
        website: 'https://projectmanager.com',
        name: 'ProjectManager Solutions',
        location: 'New York',
        position: 'Backend Developer',
        startDate: '2014-08-01',
        endDate: '2016-06-01',
        highlights: [
          'Implemented RESTful APIs to support mobile and web applications',
          'Optimized database queries for better performance',
          'Worked on implementing OAuth for secure user authentication',
        ],
      },
    ],
    interests: [
      {
        name: 'Traveling',
      },
      {
        name: 'Rock Climbing',
      },
      {
        name: 'Tech Meetups & Conferences',
      },
      {
        name: 'Open Source Projects',
      },
    ],
  },

  {
    meta: {
      theme: 'elegant',
      voice: 'shimmer',
    },
    basics: {
      name: 'Sarah Chen',
      label: 'Machine Learning Engineer',
      image: 'https://example.com/sarahchen.jpg',
      summary:
        'Machine Learning Engineer with 5+ years of experience developing and deploying ML models at scale. Specialized in computer vision and natural language processing, with a strong background in MLOps and distributed computing. Passionate about applying AI to solve real-world problems.',
      website: 'https://sarahchen.ai',
      email: 'sarah.chen@email.com',
      location: {
        city: 'San Francisco',
        countryCode: 'US',
      },
      profiles: [
        {
          username: 'sarahchen',
          url: 'https://twitter.com/sarahchen',
          network: 'twitter',
        },
        {
          url: 'https://github.com/sarahchen',
          username: 'sarahchen',
          network: 'github',
        },
        {
          url: 'https://linkedin.com/in/sarahchen',
          username: 'sarahchen',
          network: 'linkedin',
        },
      ],
    },
    education: [
      {
        endDate: '2018-05-15',
        startDate: '2016-09-01',
        area: 'Computer Science, Machine Learning',
        studyType: 'Masters',
        institution: 'Stanford University',
      },
      {
        endDate: '2016-05-15',
        startDate: '2012-09-01',
        area: 'Computer Science',
        studyType: 'Bachelors',
        institution: 'University of California, Berkeley',
      },
    ],
    skills: [
      {
        keywords: ['PyTorch', 'TensorFlow', 'Scikit-learn', 'Computer Vision', 'NLP', 'Deep Learning'],
        level: 'Expert',
        name: 'Machine Learning',
      },
      {
        keywords: ['Python', 'SQL', 'C++', 'CUDA', 'R'],
        level: 'Expert',
        name: 'Programming',
      },
      {
        keywords: ['Docker', 'Kubernetes', 'MLflow', 'Kubeflow', 'AWS SageMaker', 'GCP Vertex AI'],
        level: 'Advanced',
        name: 'MLOps',
      },
      {
        keywords: ['Spark', 'Hadoop', 'Kafka', 'AWS EMR', 'Databricks'],
        level: 'Advanced',
        name: 'Big Data',
      },
    ],
    work: [
      {
        summary:
          'Lead ML initiatives for computer vision and NLP projects. Developed and deployed models for autonomous retail checkout systems and customer sentiment analysis.',
        website: 'https://techinnovate.ai',
        name: 'TechInnovate AI',
        location: 'San Francisco',
        position: 'Senior Machine Learning Engineer',
        startDate: '2020-06-01',
        highlights: [
          'Led team of 5 ML engineers in developing real-time object detection system with 98% accuracy',
          'Reduced model inference time by 60% through CUDA optimization and model quantization',
          'Implemented MLOps pipeline reducing model deployment time from weeks to hours',
          'Published 2 papers at NeurIPS and ICML',
        ],
      },
      {
        summary:
          'Developed ML models for recommendation systems and user behavior prediction. Worked on scaling ML infrastructure to handle millions of daily predictions.',
        website: 'https://techcorp.com',
        name: 'TechCorp',
        location: 'San Francisco',
        position: 'Machine Learning Engineer',
        startDate: '2018-07-01',
        endDate: '2020-05-31',
        highlights: [
          'Built recommendation engine increasing user engagement by 45%',
          'Developed automated A/B testing framework for ML models',
          'Optimized data pipeline reducing processing time by 70%',
          'Mentored junior engineers and interns',
        ],
      },
    ],
    awards: [
      {
        title: 'Best Paper Award',
        awarder: 'NeurIPS 2022',
        date: '2022-12-01',
      },
      {
        title: 'Outstanding Research Contribution',
        awarder: 'Stanford University',
        date: '2018-05-15',
      },
    ],
    publications: [
      {
        name: 'Efficient Training of Large-Scale Vision Transformers',
        publisher: 'NeurIPS 2022',
        website: 'https://example.com/paper1',
      },
      {
        name: 'Scalable Approaches to MLOps in Production',
        publisher: 'ICML 2021',
        website: 'https://example.com/paper2',
      },
    ],
    interests: [
      {
        name: 'AI Ethics',
      },
      {
        name: 'Research Paper Reading Groups',
      },
      {
        name: 'Open Source ML Projects',
      },
      {
        name: 'Competitive Programming',
      },
    ],
    references: [
      {
        reference:
          'Sarah is an exceptional ML engineer who combines strong theoretical knowledge with practical implementation skills. Her work on our computer vision systems has been instrumental in our success.',
        name: 'Dr. James Wilson, CTO at TechInnovate AI',
      },
    ],
  },

  {
    meta: {
      theme: 'elegant',
      voice: 'shimmer',
    },
    basics: {
      name: 'Emily Rodriguez',
      label: 'UI/UX Designer',
      image: 'https://example.com/emilyrodriguez.jpg',
      summary:
        'Creative UI/UX designer with 6+ years of experience crafting intuitive digital experiences. Passionate about user-centered design, accessibility, and creating beautiful interfaces that solve real user problems. Experienced in leading design systems and collaborating with cross-functional teams.',
      website: 'https://emilyrodriguez.design',
      email: 'emily.rodriguez@email.com',
      location: {
        city: 'San Diego',
        countryCode: 'US',
      },
      profiles: [
        {
          username: 'emilyrodriguez',
          url: 'https://dribbble.com/emilyrodriguez',
          network: 'dribbble',
        },
        {
          url: 'https://behance.net/emilyrodriguez',
          username: 'emilyrodriguez',
          network: 'behance',
        },
        {
          url: 'https://linkedin.com/in/emilyrodriguez',
          username: 'emilyrodriguez',
          network: 'linkedin',
        },
      ],
    },
    education: [
      {
        endDate: '2017-05-15',
        startDate: '2013-09-01',
        area: 'Interactive Design',
        studyType: 'Bachelor of Fine Arts',
        institution: 'Rhode Island School of Design',
      },
    ],
    skills: [
      {
        keywords: ['User Interface Design', 'Design Systems', 'Visual Design', 'Typography', 'Color Theory'],
        level: 'Expert',
        name: 'UI Design',
      },
      {
        keywords: ['User Research', 'Wireframing', 'Prototyping', 'User Testing', 'Information Architecture'],
        level: 'Expert',
        name: 'UX Design',
      },
      {
        keywords: ['Figma', 'Sketch', 'Adobe XD', 'Illustrator', 'Photoshop', 'InVision', 'Principle'],
        level: 'Expert',
        name: 'Design Tools',
      },
      {
        keywords: ['HTML', 'CSS', 'JavaScript', 'React', 'Animation'],
        level: 'Intermediate',
        name: 'Development',
      },
    ],
    work: [
      {
        summary:
          'Lead the UI/UX design team in creating and maintaining a comprehensive design system. Responsible for establishing design guidelines, patterns, and components used across multiple product lines.',
        website: 'https://designtech.io',
        name: 'DesignTech Solutions',
        location: 'San Diego',
        position: 'Senior UI/UX Designer',
        startDate: '2020-03-01',
        highlights: [
          'Created and implemented a design system used by 50+ designers and developers',
          'Reduced design inconsistencies by 80% through standardized components',
          'Led redesign of flagship product resulting in 40% increase in user engagement',
          'Mentored junior designers and facilitated design workshops',
        ],
      },
      {
        summary:
          'Designed user interfaces for various client projects including mobile apps, websites, and enterprise software. Worked closely with development teams to ensure pixel-perfect implementation.',
        website: 'https://creativestudio.com',
        name: 'Creative Studio',
        location: 'Los Angeles',
        position: 'UI Designer',
        startDate: '2017-06-01',
        endDate: '2020-02-28',
        highlights: [
          'Designed interfaces for 20+ successful client projects',
          'Improved mobile app usability scores by 45%',
          'Implemented accessibility standards across all projects',
          'Created interactive prototypes for user testing',
        ],
      },
    ],
    awards: [
      {
        title: 'Best Digital Design',
        awarder: 'AIGA Design Awards',
        date: '2022',
      },
      {
        title: 'Excellence in UI Design',
        awarder: 'UX Design Awards',
        date: '2021',
      },
    ],
    projects: [
      {
        name: 'Healthcare App Redesign',
        description:
          'Complete redesign of a healthcare provider platform focusing on improved accessibility and user experience',
        highlights: ['Increased user satisfaction by 60%', 'Reduced task completion time by 45%'],
        url: 'https://example.com/healthcare-redesign',
      },
    ],
    interests: [
      {
        name: 'Typography',
      },
      {
        name: 'Digital Art',
      },
      {
        name: 'Design Systems',
      },
      {
        name: 'Accessibility',
      },
    ],
    references: [
      {
        reference:
          'Emily is an exceptional designer who brings both creativity and strategic thinking to every project. Her work on our design system has transformed how we approach product design.',
        name: 'Michael Chang, Head of Product at DesignTech Solutions',
      },
    ],
  },

  {
    meta: {
      theme: 'elegant',
      voice: 'shimmer',
    },
    basics: {
      name: 'Alex Martinez',
      label: 'Product Manager',
      image: 'https://example.com/alexmartinez.jpg',
      summary:
        'Strategic Product Manager with 8+ years of experience leading cross-functional teams to deliver innovative digital products. Proven track record of driving user growth, revenue, and product adoption through data-driven decision making and strong stakeholder management. Experienced in both B2B and B2C products with a focus on SaaS platforms.',
      website: 'https://alexmartinez.pm',
      email: 'alex.martinez@email.com',
      location: {
        city: 'Seattle',
        countryCode: 'US',
      },
      profiles: [
        {
          username: 'alexmartinez',
          url: 'https://linkedin.com/in/alexmartinez',
          network: 'linkedin',
        },
        {
          url: 'https://medium.com/@alexmartinez',
          username: 'alexmartinez',
          network: 'medium',
        },
      ],
    },
    education: [
      {
        endDate: '2015-05-15',
        startDate: '2013-09-01',
        area: 'Business Administration',
        studyType: 'Masters',
        institution: 'University of Washington',
      },
      {
        endDate: '2013-05-15',
        startDate: '2009-09-01',
        area: 'Computer Science',
        studyType: 'Bachelors',
        institution: 'University of California, Davis',
      },
    ],
    skills: [
      {
        keywords: ['Product Strategy', 'Roadmap Planning', 'Go-to-Market', 'Product Discovery', 'User Stories'],
        level: 'Expert',
        name: 'Product Management',
      },
      {
        keywords: ['A/B Testing', 'SQL', 'Google Analytics', 'Mixpanel', 'Product Analytics'],
        level: 'Advanced',
        name: 'Data Analysis',
      },
      {
        keywords: ['Agile', 'Scrum', 'Kanban', 'JIRA', 'Confluence'],
        level: 'Expert',
        name: 'Project Management',
      },
      {
        keywords: ['User Research', 'Customer Journey Mapping', 'Persona Development', 'Usability Testing'],
        level: 'Advanced',
        name: 'UX Research',
      },
    ],
    work: [
      {
        summary:
          'Lead product strategy and execution for enterprise SaaS platform serving 500+ corporate clients. Manage a product suite generating $50M+ in annual revenue.',
        website: 'https://enterprisetech.com',
        name: 'EnterpriseTech Solutions',
        location: 'Seattle',
        position: 'Senior Product Manager',
        startDate: '2019-06-01',
        highlights: [
          'Launched new analytics dashboard increasing user engagement by 200%',
          'Led product strategy resulting in 40% YoY revenue growth',
          'Developed product vision and roadmap for AI-powered features',
          'Managed team of 15 including engineers, designers, and data scientists',
          'Reduced customer churn by 30% through strategic product improvements',
        ],
      },
      {
        summary:
          'Owned consumer-facing mobile app with 2M+ monthly active users. Led product development from conception to launch.',
        website: 'https://consumerapps.com',
        name: 'ConsumerApps Inc.',
        location: 'San Francisco',
        position: 'Product Manager',
        startDate: '2016-03-01',
        endDate: '2019-05-31',
        highlights: [
          'Increased MAU from 500K to 2M through strategic feature releases',
          'Implemented data-driven experimentation program',
          'Launched premium subscription tier generating $5M in first year',
          'Coordinated with marketing team on successful App Store optimization',
        ],
      },
      {
        summary: 'Started as Associate PM and promoted to PM. Managed e-commerce platform features.',
        website: 'https://ecommerce.com',
        name: 'E-commerce Solutions',
        location: 'Seattle',
        position: 'Associate Product Manager',
        startDate: '2015-06-01',
        endDate: '2016-02-28',
        highlights: [
          'Redesigned checkout flow reducing cart abandonment by 25%',
          'Implemented A/B testing framework for feature optimization',
          'Collaborated with UX team on usability improvements',
        ],
      },
    ],
    awards: [
      {
        title: 'Product Leader of the Year',
        awarder: 'Product Management Association',
        date: '2022',
      },
      {
        title: 'Innovation Excellence Award',
        awarder: 'EnterpriseTech Solutions',
        date: '2021',
      },
    ],
    publications: [
      {
        name: 'Building Data-Driven Product Teams',
        publisher: 'Medium - The Product Blog',
        website: 'https://medium.com/product-blog/data-driven-teams',
      },
    ],
    interests: [
      {
        name: 'Product Strategy',
      },
      {
        name: 'Technology Trends',
      },
      {
        name: 'Data Science',
      },
      {
        name: 'Startup Mentoring',
      },
    ],
    references: [
      {
        reference:
          'Alex is an exceptional product leader who consistently delivers results through a combination of strategic thinking, technical understanding, and excellent stakeholder management. Their ability to balance user needs with business objectives is outstanding.',
        name: 'Sarah Johnson, VP of Product at EnterpriseTech Solutions',
      },
      {
        reference:
          'Working with Alex was transformative for our product organization. They brought structure, data-driven decision making, and a clear vision that helped us scale significantly.',
        name: 'David Chen, CTO at ConsumerApps Inc.',
      },
    ],
  },

  {
    meta: {
      theme: 'modern',
      voice: 'echo',
    },
    basics: {
      name: 'Michael Zhang',
      label: 'Software Engineer',
      image: 'https://example.com/michaelzhang.jpg',
      summary:
        'Results-driven Software Engineer with 6+ years of experience specializing in distributed systems and cloud architecture. Strong background in building scalable microservices and optimizing system performance. Passionate about clean code, automation, and mentoring junior developers.',
      website: 'https://michaelzhang.dev',
      email: 'michael.zhang@email.com',
      location: {
        city: 'Austin',
        countryCode: 'US',
      },
      profiles: [
        {
          username: 'mzhang',
          url: 'https://github.com/mzhang',
          network: 'github',
        },
        {
          url: 'https://linkedin.com/in/michaelzhang',
          username: 'michaelzhang',
          network: 'linkedin',
        },
      ],
    },
    education: [
      {
        endDate: '2017-05-15',
        startDate: '2013-09-01',
        area: 'Computer Science',
        studyType: 'Bachelor of Science',
        institution: 'University of Texas at Austin',
      },
    ],
    skills: [
      {
        keywords: ['Java', 'Spring Boot', 'Python', 'Go', 'C++'],
        level: 'Expert',
        name: 'Programming Languages',
      },
      {
        keywords: ['AWS', 'Kubernetes', 'Docker', 'Terraform', 'Jenkins', 'GitLab CI'],
        level: 'Expert',
        name: 'DevOps & Cloud',
      },
      {
        keywords: ['MongoDB', 'PostgreSQL', 'Redis', 'Elasticsearch', 'Kafka'],
        level: 'Advanced',
        name: 'Databases & Messaging',
      },
      {
        keywords: ['System Design', 'Microservices', 'RESTful APIs', 'GraphQL', 'gRPC'],
        level: 'Expert',
        name: 'Architecture',
      },
    ],
    work: [
      {
        summary:
          'Lead development of cloud-native microservices platform serving millions of requests per day. Architect and implement scalable solutions for high-throughput data processing.',
        website: 'https://techcorp.com',
        name: 'TechCorp',
        location: 'Austin',
        position: 'Senior Software Engineer',
        startDate: '2020-01-01',
        highlights: [
          'Architected and led implementation of distributed data processing pipeline handling 10TB+ daily',
          'Reduced infrastructure costs by 40% through optimization and auto-scaling',
          'Mentored team of 5 junior engineers and established coding standards',
          'Implemented CI/CD pipeline reducing deployment time from days to hours',
          'Led migration from monolith to microservices architecture',
        ],
      },
      {
        summary:
          'Developed and maintained core backend services for e-commerce platform. Focused on payment processing and inventory management systems.',
        website: 'https://ecommerce.io',
        name: 'E-commerce Solutions',
        location: 'Seattle',
        position: 'Software Engineer',
        startDate: '2017-06-01',
        endDate: '2019-12-31',
        highlights: [
          'Built real-time inventory management system processing 1M+ transactions daily',
          'Improved API response times by 60% through caching and optimization',
          'Implemented fault-tolerant payment processing system with 99.99% uptime',
          'Led adoption of containerization and Kubernetes deployment',
        ],
      },
    ],
    awards: [
      {
        title: 'Patent: Distributed System for Data Processing',
        awarder: 'US Patent Office',
        date: '2022',
      },
      {
        title: 'Engineering Excellence Award',
        awarder: 'TechCorp',
        date: '2021',
      },
    ],
    projects: [
      {
        name: 'Open Source Load Balancer',
        description: 'Developed a high-performance load balancer in Go with advanced traffic management features',
        highlights: ['1000+ GitHub stars', 'Used by several production systems'],
        url: 'https://github.com/mzhang/loadbalancer',
      },
    ],
    interests: [
      {
        name: 'Distributed Systems',
      },
      {
        name: 'Open Source',
      },
      {
        name: 'System Architecture',
      },
      {
        name: 'Performance Optimization',
      },
    ],
    references: [
      {
        reference:
          'Michael is an exceptional engineer who combines deep technical expertise with great leadership skills. His work on our microservices architecture has been transformative for our organization.',
        name: 'Jennifer Lee, Engineering Director at TechCorp',
      },
    ],
  },

  {
    meta: {
      theme: 'modern',
      voice: 'echo',
    },
    basics: {
      name: 'David Kumar',
      label: 'Machine Learning Engineer',
      image: 'https://example.com/davidkumar.jpg',
      summary:
        'Machine Learning Engineer with 4+ years of experience specializing in NLP and recommendation systems. Strong background in deploying ML models at scale and optimizing model performance. Passionate about applying ML to solve real-world business problems.',
      website: 'https://davidkumar.ai',
      email: 'david.kumar@email.com',
      location: {
        city: 'Boston',
        countryCode: 'US',
      },
      profiles: [
        {
          username: 'dkumar',
          url: 'https://github.com/dkumar',
          network: 'github',
        },
        {
          url: 'https://linkedin.com/in/davidkumar',
          username: 'davidkumar',
          network: 'linkedin',
        },
      ],
    },
    education: [
      {
        endDate: '2019-05-15',
        startDate: '2017-09-01',
        area: 'Artificial Intelligence',
        studyType: 'Masters',
        institution: 'Carnegie Mellon University',
      },
      {
        endDate: '2017-05-15',
        startDate: '2013-09-01',
        area: 'Computer Science',
        studyType: 'Bachelors',
        institution: 'University of Illinois at Urbana-Champaign',
      },
    ],
    skills: [
      {
        keywords: ['PyTorch', 'TensorFlow', 'Hugging Face', 'BERT', 'GPT', 'Transformers'],
        level: 'Expert',
        name: 'Machine Learning',
      },
      {
        keywords: ['Python', 'SQL', 'Java', 'Shell Scripting'],
        level: 'Expert',
        name: 'Programming',
      },
      {
        keywords: ['AWS SageMaker', 'MLflow', 'DVC', 'Docker', 'Kubernetes'],
        level: 'Advanced',
        name: 'MLOps',
      },
    ],
    work: [
      {
        summary:
          'Lead ML initiatives focusing on NLP and recommendation systems. Developed and deployed models for content personalization and user behavior prediction.',
        website: 'https://aitech.com',
        name: 'AITech Solutions',
        location: 'Boston',
        position: 'Senior Machine Learning Engineer',
        startDate: '2021-01-01',
        highlights: [
          'Developed recommendation system increasing user engagement by 35%',
          'Built and deployed NLP models for content classification with 92% accuracy',
          'Reduced model training time by 50% through distributed training',
          'Led team of 3 ML engineers and mentored junior developers',
        ],
      },
      {
        summary: 'Worked on developing and deploying ML models for fraud detection and risk assessment.',
        website: 'https://fintech.com',
        name: 'FinTech Corp',
        location: 'New York',
        position: 'Machine Learning Engineer',
        startDate: '2019-06-01',
        endDate: '2020-12-31',
        highlights: [
          'Implemented fraud detection system reducing fraud cases by 60%',
          'Optimized model inference pipeline reducing latency by 40%',
          'Developed automated model monitoring and retraining pipeline',
        ],
      },
    ],
    awards: [
      {
        title: 'Best ML Implementation',
        awarder: 'FinTech Innovation Awards',
        date: '2020',
      },
    ],
    interests: [
      {
        name: 'Natural Language Processing',
      },
      {
        name: 'MLOps',
      },
      {
        name: 'Deep Learning Research',
      },
    ],
    references: [
      {
        reference:
          'David is an exceptional ML engineer who consistently delivers high-quality solutions. His work on our recommendation system has significantly improved our user engagement metrics.',
        name: 'Sarah Thompson, Director of AI at AITech Solutions',
      },
    ],
  },
]
