import { useFindOrCreateEmptyConversation, useUpsertConversationMessage, type ConversationFragment } from '@/graphql/generated/query.types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { WavRenderer } from '@/lib/wav_renderer';
import { WavRecorder, WavStreamPlayer } from '@/lib/wavtools/index.js';
import { RealtimeClient } from '@openai/realtime-api-beta';
import type { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { PushToTalkButton } from '@/components/PushToTalkButton';
import { Toggle } from '@/components/Toggle';
import { ArrowDown, ArrowUp, X, Zap } from 'react-feather';
import { OrganizationContext } from '@/components/auth/OrganizationContext';
import { HStack } from '@/components/HStack';
import { Resume } from '@/components/Resume';
import { OrganizationDropdown } from '@/components/sidebar/OrganizationDropdown';
import { Spacer } from '@/components/Spacer';
import { Button } from '@/components/ui/button';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import { VStack } from '@/components/VStack';
import { checkOk } from '@/lib/checkOk';
import { getRandomInt } from '@/lib/random';
import { resumes } from '@/lib/resume';
import type { ItemContentDeltaType } from '@openai/realtime-api-beta/dist/lib/conversation';
const LOCAL_RELAY_SERVER_URL: string = process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : 'https://fadeaway-production.up.railway.app';

/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: {
    [key: string]: any;
  };
}
export default function IndexPage() {
  const organization = useContext(OrganizationContext);
  const [conversation, setConversation] = useState<ConversationFragment | null>(null);
  const [findOrCreateEmptyConversation] = useFindOrCreateEmptyConversation();
  const [upsertConversationMessage] = useUpsertConversationMessage();
  const [resume, setResume] = useState(resumes[0]);
  const conversationRef = useRef<ConversationFragment | null>(null);
  useEffect(() => {
    conversationRef.current = conversation;
  }, [conversation]);

  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({
    sampleRate: 24000
  }));
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({
    sampleRate: 24000
  }));
  const clientRef = useRef<RealtimeClient>(new RealtimeClient({
    url: LOCAL_RELAY_SERVER_URL
  }));

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - memoryKv is for set_memory() function
   * - coords, marker are for get_weather() function
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [expandedEvents, setExpandedEvents] = useState<{
    [key: string]: boolean;
  }>({});
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  // const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({})

  /**
   * Utility for formatting the timing of logs
   */
  const formatTime = useCallback((timestamp: string) => {
    const startTime = startTimeRef.current;
    const t0 = new Date(startTime).valueOf();
    const t1 = new Date(timestamp).valueOf();
    const delta = t1 - t0;
    const hs = Math.floor(delta / 10) % 100;
    const s = Math.floor(delta / 1000) % 60;
    const m = Math.floor(delta / 60_000) % 60;
    const pad = (n: number) => {
      let s = n + '';
      while (s.length < 2) {
        s = '0' + s;
      }
      return s;
    };
    return `${pad(m)}:${pad(s)}.${pad(hs)}`;
  }, []);

  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Set state variables
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setRealtimeEvents([]);
    setItems(client.conversation.getItems());

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    // Connect to realtime API
    await client.connect();
    client.sendUserMessageContent([{
      type: `input_text`,
      text: `Hello!`
      // text: `For testing purposes, I want you to list ten car brands. Number each item, e.g. "one (or whatever number you are one): the item name".`
    }]);
    if (client.getTurnDetectionType() === 'server_vad') {
      await wavRecorder.record(data => client.appendInputAudio(data.mono));
    }
  }, []);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);
    // setMemoryKv({})

    const client = clientRef.current;
    client.disconnect();
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();
    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);
  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  /**
   * In push-to-talk mode, start recording
   * .appendInputAudio() for each sample
   */
  const startRecording = async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const {
        trackId,
        offset
      } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record(data => client.appendInputAudio(data.mono));
  };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording = async () => {
    setIsRecording(false);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  };

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === 'none' ? null : {
        type: 'server_vad'
      }
    });
    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record(data => client.appendInputAudio(data.mono));
    }
    setCanPushToTalk(value === 'none');
  };
  const updateSessionFromResume = useCallback((resume: any) => {
    const client = clientRef.current;
    client.updateSession({
      instructions: `System settings:
Tool use: enabled.

Instructions:
- Do not ask the user how you can help them. You are a candidate for a job interview and you are chatting with the interviewer.
- Please make sure to respond with a helpful voice via audio
- Be kind and curteous
- Do not ask the user questions unless they explicitly ask you to
- Be open to exploration and conversation
- Be concise and to the point, dive deeper into topics if the interviewer asks you to

Personality:
- Be upbeat and genuine
- Try speaking quickly as if excited

Here's your resume in JSON format:
\`\`\`json
${JSON.stringify(resume)}
\`\`\`
`,
      voice: resume.meta.voice
    });
  }, []);

  /**
   * Auto-scroll the event logs
   */
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  const conversationContentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (conversationContentRef.current) {
      conversationContentRef.current.scrollTop = conversationContentRef.current.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;
    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;
    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording ? wavRecorder.getFrequencies('voice') : {
              values: new Float32Array([0])
            };
            WavRenderer.drawBars(clientCanvas, clientCtx, result.values, '#0099ff', 10, 0, 8);
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser ? wavStreamPlayer.getFrequencies('voice') : {
              values: new Float32Array([0])
            };
            WavRenderer.drawBars(serverCanvas, serverCtx, result.values, '#009900', 10, 0, 8);
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();
    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // Set instructions
    updateSessionFromResume(resume);

    // Set transcription, otherwise we don't get user transcriptions back
    client.updateSession({
      input_audio_transcription: {
        model: 'whisper-1'
      }
    });

    // Add tools
    // client.addTool(
    //   {
    //     name: 'set_memory',
    //     description: 'Saves important data about the user into memory.',
    //     parameters: {
    //       type: 'object',
    //       properties: {
    //         key: {
    //           type: 'string',
    //           description: 'The key of the memory value. Always use lowercase and underscores, no other characters.',
    //         },
    //         value: {
    //           type: 'string',
    //           description: 'Value can be anything represented as a string',
    //         },
    //       },
    //       required: ['key', 'value'],
    //     },
    //   },
    //   async ({ key, value }: { [key: string]: any }) => {
    //     setMemoryKv(memoryKv => {
    //       const newKv = { ...memoryKv }
    //       newKv[key] = value
    //       return newKv
    //     })
    //     return { ok: true }
    //   }
    // )

    // handle realtime events from client + server for event logging
    client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents(realtimeEvents => {
        const lastEvent = realtimeEvents[realtimeEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          // if we receive multiple events in a row, aggregate them for display purposes
          lastEvent.count = (lastEvent.count || 0) + 1;
          return realtimeEvents.slice(0, -1).concat(lastEvent);
        } else {
          return realtimeEvents.concat(realtimeEvent);
        }
      });
    });
    client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const {
          trackId,
          offset
        } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on('conversation.updated', async ({
      item,
      delta
    }: {
      item: ItemType;
      delta: ItemContentDeltaType | null;
    }) => {
      const items = client.conversation.getItems();
      console.log('conversation.updated', {
        item,
        delta,
        role: item.role,
        id: item.id
      });
      const conversation = conversationRef.current;
      checkOk(conversation, 'No conversation');
      checkOk(item.role, 'No role');
      if (delta == null) {
        // new item is created
        upsertConversationMessage({
          variables: {
            upserter: {
              conversationId: conversation.id,
              content: item.formatted.text || item.formatted.transcript || '',
              role: item.role,
              openaiId: item.id
            }
          }
        });
      }
      if (item.role === 'user' && delta?.transcript) {
        upsertConversationMessage({
          variables: {
            upserter: {
              conversationId: conversation.id,
              content: delta.transcript,
              role: item.role,
              openaiId: item.id
            }
          }
        });
      }
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if ('status' in item && item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(item.formatted.audio, 24000, 24000);
        item.formatted.file = wavFile;
      }
      setItems(items);
    });
    setItems(client.conversation.getItems());
    return () => {
      // cleanup; resets to defaults
      client.reset();
    };
  }, []);
  useEffect(() => {
    findOrCreateEmptyConversation().then(res => {
      if (res.data?.findOrCreateEmptyConversation) {
        setConversation(res.data.findOrCreateEmptyConversation);
      }
    });
  }, []);
  return <ResizablePanelGroup direction="horizontal" className="" data-sentry-element="ResizablePanelGroup" data-sentry-component="IndexPage" data-sentry-source-file="index.tsx">
      <ResizablePanel defaultSize={50} minSize={20} data-sentry-element="ResizablePanel" data-sentry-source-file="index.tsx">
        <ResizablePanelGroup direction="vertical" data-sentry-element="ResizablePanelGroup" data-sentry-source-file="index.tsx">
          <ResizablePanel defaultSize={75} minSize={20} className="overflow-auto" data-sentry-element="ResizablePanel" data-sentry-source-file="index.tsx">
            <VStack className="relative flex flex-col h-full w-full px-8 py-4" data-sentry-element="VStack" data-sentry-source-file="index.tsx">
              <div className="visualization absolute flex bottom-1 right-2 p-1 rounded-2xl z-10 gap-0.5">
                <div className="relative flex items-center h-10 w-[100px] gap-1 text-[#0099ff]">
                  <canvas ref={clientCanvasRef} className="w-full h-full" />
                </div>
                <div className="relative flex items-center h-10 w-[100px] gap-1 text-[#009900]">
                  <canvas ref={serverCanvasRef} className="w-full h-full" />
                </div>
              </div>
              <div className="flex-shrink-0 pt-4 pb-1">events</div>
              <div className="flex-grow py-2 pt-1 leading-[1.2em] overflow-auto text-[#6e6e7f]" ref={eventsScrollRef}>
                {!realtimeEvents.length && `awaiting connection...`}
                {realtimeEvents.map((realtimeEvent, i) => {
                const count = realtimeEvent.count;
                const event = {
                  ...realtimeEvent.event
                };
                if (event.type === 'input_audio_buffer.append') {
                  event.audio = `[trimmed: ${event.audio.length} bytes]`;
                } else if (event.type === 'response.audio.delta') {
                  event.delta = `[trimmed: ${event.delta.length} bytes]`;
                }
                return <HStack key={event.event_id} className="gap-2">
                      <div className="event-timestamp">{formatTime(realtimeEvent.time)}</div>
                      <div className="event-details">
                        <HStack className="gap-2" onClick={() => {
                      // toggle event details
                      const id = event.event_id;
                      const expanded = {
                        ...expandedEvents
                      };
                      if (expanded[id]) {
                        delete expanded[id];
                      } else {
                        expanded[id] = true;
                      }
                      setExpandedEvents(expanded);
                    }}>
                          <HStack className={`event-source ${event.type === 'error' ? 'error' : realtimeEvent.source}`}>
                            {realtimeEvent.source === 'client' ? <ArrowUp /> : <ArrowDown />}
                            <span>{event.type === 'error' ? 'error!' : realtimeEvent.source}</span>
                          </HStack>
                          <HStack className="w-fit">
                            <span>{event.type}</span>
                            {count && <span> (${count})</span>}
                          </HStack>
                        </HStack>
                        {!!expandedEvents[event.event_id] && <div className="event-payload">{JSON.stringify(event, null, 2)}</div>}
                      </div>
                    </HStack>;
              })}
              </div>
            </VStack>
          </ResizablePanel>
          <ResizableHandle data-sentry-element="ResizableHandle" data-sentry-source-file="index.tsx" />
          <ResizablePanel minSize={20} data-sentry-element="ResizablePanel" data-sentry-source-file="index.tsx">
            <VStack className="px-4 py-2 h-full gap-1" data-sentry-element="VStack" data-sentry-source-file="index.tsx">
              <div className="flex-shrink-0 pt-4 pb-1">conversation</div>
              <VStack className="py-2 text-[#6e6e7f] relative overflow-auto gap-2" ref={conversationContentRef} data-sentry-element="VStack" data-sentry-source-file="index.tsx">
                {!items.length && `awaiting connection...`}
                {items.map((conversationItem, i) => {
                return <HStack className="relative flex gap-4 mb-4 group items-start" key={conversationItem.id}>
                      <div className="w-[80px] shrink-0" style={{
                    color: conversationItem.role === 'user' ? '#0099ff' : '#009900'
                  }}>
                        <div>{(conversationItem.role || conversationItem.type).replaceAll('_', ' ')}</div>
                        {/* <div className="close" onClick={() => deleteConversationItem(conversationItem.id)}>
                          <X />
                         </div> */}
                      </div>
                      <VStack className="gap-1">
                        {/* tool response */}
                        {conversationItem.type === 'function_call_output' && <div>{conversationItem.formatted.output}</div>}
                        {/* tool call */}
                        {!!conversationItem.formatted.tool && <div>
                            {conversationItem.formatted.tool.name}({conversationItem.formatted.tool.arguments})
                          </div>}
                        {!conversationItem.formatted.tool && conversationItem.role === 'user' && <div>
                            {conversationItem.formatted.transcript || (conversationItem.formatted.audio?.length ? '(awaiting transcript)' : conversationItem.formatted.text || '(item sent)')}
                          </div>}
                        {!conversationItem.formatted.tool && conversationItem.role === 'assistant' && <div>
                            {conversationItem.formatted.transcript || conversationItem.formatted.text || '(truncated)'}
                          </div>}
                        {conversationItem.formatted.file && <audio src={conversationItem.formatted.file.url} controls />}
                      </VStack>
                    </HStack>;
              })}
              </VStack>
              <Spacer data-sentry-element="Spacer" data-sentry-source-file="index.tsx" />
              <HStack className="shrink-0 flex items-center justify-center gap-4" data-sentry-element="HStack" data-sentry-source-file="index.tsx">
                <OrganizationDropdown data-sentry-element="OrganizationDropdown" data-sentry-source-file="index.tsx" />
                <Toggle defaultValue={false} labels={['manual', 'vad']} values={['none', 'server_vad']} onChange={(_, value) => changeTurnEndType(value)} data-sentry-element="Toggle" data-sentry-source-file="index.tsx" />
                {isConnected && canPushToTalk && <PushToTalkButton label={isRecording ? 'release to send' : 'push to talk'} buttonStyle={isRecording ? 'alert' : 'regular'} disabled={!isConnected || !canPushToTalk} onMouseDown={startRecording} onMouseUp={stopRecording} />}
                <PushToTalkButton label={isConnected ? 'disconnect' : 'connect'} iconPosition={isConnected ? 'end' : 'start'} icon={isConnected ? X : Zap} buttonStyle={isConnected ? 'regular' : 'action'} onClick={isConnected ? disconnectConversation : connectConversation} data-sentry-element="PushToTalkButton" data-sentry-source-file="index.tsx" />
                {!isConnected && <Button onClick={() => {
                findOrCreateEmptyConversation().then(res => {
                  if (res.data?.findOrCreateEmptyConversation) {
                    setConversation(res.data.findOrCreateEmptyConversation);
                  }
                });
                const nextResume = resumes[getRandomInt(0, resumes.length - 1)];
                setResume(nextResume);
                updateSessionFromResume(nextResume);
              }}>
                    Next Candidate
                  </Button>}
              </HStack>
            </VStack>
          </ResizablePanel>
        </ResizablePanelGroup>
      </ResizablePanel>
      <ResizableHandle data-sentry-element="ResizableHandle" data-sentry-source-file="index.tsx" />
      <ResizablePanel minSize={20} data-sentry-element="ResizablePanel" data-sentry-source-file="index.tsx">
        <Resume className="overflow-auto h-full" resume={resume} data-sentry-element="Resume" data-sentry-source-file="index.tsx" />
      </ResizablePanel>
    </ResizablePanelGroup>;
}