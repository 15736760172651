import { cn } from '@/lib/cn';
interface ResumeProps {
  className?: string;
  resume: any;
}
export const Resume = (props: ResumeProps) => {
  const {
    resume,
    className
  } = props;
  const {
    basics,
    work,
    education,
    skills,
    interests,
    references
  } = resume;
  if (resume.meta.theme === 'elegant') {
    return <ElegantResume {...props} />;
  }
  return <ModernResume {...props} data-sentry-element="ModernResume" data-sentry-component="Resume" data-sentry-source-file="Resume.tsx" />;
};
const ElegantResume = (props: ResumeProps) => {
  const {
    resume,
    className
  } = props;
  const {
    basics,
    work,
    education,
    skills,
    interests,
    references
  } = resume;
  return <div className={cn('bg-white text-slate-800 px-12 py-6 max-w-5xl mx-auto', className)} data-sentry-component="ElegantResume" data-sentry-source-file="Resume.tsx">
      {/* Header */}
      <header className="text-center border-b border-slate-200 pb-6 mb-8">
        <h1 className="font-serif text-5xl font-normal mb-3">{basics.name}</h1>
        <p className="font-serif text-2xl text-slate-600 mb-2">{basics.label}</p>
        <div className="flex justify-center gap-4 text-sm">
          <p>{basics.email}</p>
          <span>•</span>
          <p>{basics.website}</p>
          <span>•</span>
          <p>
            {basics.location.city}, {basics.location.countryCode}
          </p>
        </div>
        <p className="mt-4 max-w-2xl mx-auto text-slate-600 leading-relaxed">{basics.summary}</p>
      </header>

      {/* Experience */}
      <section className="mb-12">
        <h2 className="font-serif text-2xl text-center mb-8">Professional Experience</h2>
        {work.map((job: any, index: number) => <div key={index} className="mb-8 last:mb-0">
            <div className="text-center mb-3">
              <h3 className="font-serif text-xl mb-1">{job.name}</h3>
              <p className="font-medium text-lg mb-1">{job.position}</p>
              <p className="text-sm text-slate-600">
                {job.startDate} - {job.endDate || 'Present'}
              </p>
            </div>
            <p className="text-center mb-4 max-w-3xl mx-auto text-slate-700">{job.summary}</p>
            {job.highlights && <ul className="max-w-2xl mx-auto space-y-2">
                {job.highlights.map((highlight: string, i: number) => <li key={i} className="text-slate-700 leading-relaxed flex items-start">
                    <span className="mr-2 text-slate-400">•</span>
                    {highlight}
                  </li>)}
              </ul>}
          </div>)}
      </section>

      {/* Skills */}
      <section className="mb-12">
        <h2 className="font-serif text-2xl text-center mb-8">Areas of Expertise</h2>
        <div className="flex flex-wrap justify-center gap-12">
          {skills.map((skill: any, index: number) => <div key={index} className="text-center">
              <h3 className="font-serif text-lg mb-3">{skill.name}</h3>
              <div className="flex flex-wrap gap-3 justify-center max-w-xs">
                {skill.keywords.map((keyword: string, i: number) => <span key={i} className="text-slate-600 bg-slate-50 px-3 py-1 rounded-full text-sm">
                    {keyword}
                  </span>)}
              </div>
            </div>)}
        </div>
      </section>

      {/* Education */}
      <section className="mb-12">
        <h2 className="font-serif text-2xl text-center mb-8">Education</h2>
        <div className="flex flex-col items-center gap-6">
          {education.map((edu: any, index: number) => <div key={index} className="text-center">
              <h3 className="font-serif text-lg mb-1">{edu.institution}</h3>
              <p className="font-medium text-slate-700">
                {edu.area} - {edu.studyType}
              </p>
              <p className="text-sm text-slate-600">
                {edu.startDate} - {edu.endDate}
              </p>
            </div>)}
        </div>
      </section>

      {/* Interests */}
      <section className="mb-8">
        <h2 className="font-serif text-2xl text-center mb-6">Interests</h2>
        <div className="flex flex-wrap justify-center gap-3">
          {interests.map((interest: any, index: number) => <span key={index} className="bg-slate-50 px-4 py-2 rounded-full text-slate-700">
              {interest.name}
            </span>)}
        </div>
      </section>

      {/* References - Optional */}
      {references && references.length > 0 && <section className="text-center">
          <h2 className="font-serif text-2xl mb-4">References</h2>
          <p className="text-slate-600 italic">Available upon request</p>
        </section>}
    </div>;
};
const ModernResume = (props: ResumeProps) => {
  const {
    resume,
    className
  } = props;
  const {
    basics,
    work,
    education,
    skills,
    interests,
    references
  } = resume;

  // Return modern theme (existing implementation)
  return <div className={cn('bg-white text-gray-900 px-16 py-8', className)} data-sentry-component="ModernResume" data-sentry-source-file="Resume.tsx">
      {/* Header Section */}
      <header className="border-b-2 pb-4 mb-4">
        <h1 className="text-4xl font-bold mb-2 text-gray-900">{basics.name}</h1>
        <div className="flex justify-between items-start">
          <div>
            <p className="text-xl text-gray-900">{basics.label}</p>
            <p className="text-gray-900 mt-2">{basics.summary}</p>
          </div>
          <div className="text-right">
            <p className="text-gray-900">{basics.email}</p>
            <p className="text-gray-900">{basics.website}</p>
            <p className="text-gray-900">
              {basics.location.city}, {basics.location.countryCode}
            </p>
          </div>
        </div>
      </header>

      {/* Experience Section */}
      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-4">Professional Experience</h2>
        {work.map((job: any, index: number) => <div key={index} className="mb-4">
            <div className="flex justify-between">
              <h3 className="font-semibold text-gray-900">{job.name}</h3>
              <p className="text-gray-900">
                {job.startDate} - {job.endDate || 'Present'}
              </p>
            </div>
            <p className="font-medium text-gray-900">{job.position}</p>
            <p className="text-gray-900 mt-1">{job.summary}</p>
            {job.highlights && <ul className="list-disc ml-4 mt-2">
                {job.highlights.map((highlight: string, i: number) => <li key={i} className="text-gray-900">
                    {highlight}
                  </li>)}
              </ul>}
          </div>)}
      </section>

      {/* Skills Section */}
      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-4">Skills</h2>
        <div className="grid grid-cols-3 gap-4">
          {skills.map((skill: any, index: number) => <div key={index}>
              <h3 className="font-semibold">{skill.name}</h3>
              <ul className="list-disc ml-4">
                {skill.keywords.map((keyword: string, i: number) => <li key={i} className="text-gray-800">
                    {keyword}
                  </li>)}
              </ul>
            </div>)}
        </div>
      </section>

      {/* Education Section */}
      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-4">Education</h2>
        {education.map((edu: any, index: number) => <div key={index}>
            <h3 className="font-semibold">{edu.institution}</h3>
            <p>
              {edu.area} - {edu.studyType}
            </p>
            <p className="text-gray-800">
              {edu.startDate} - {edu.endDate}
            </p>
          </div>)}
      </section>

      {/* Interests Section */}
      <section className="mb-6">
        <h2 className="text-2xl font-bold mb-4">Interests</h2>
        <div className="flex gap-4">
          {interests.map((interest: any, index: number) => <span key={index} className="bg-gray-100 px-3 py-1 rounded">
              {interest.name}
            </span>)}
        </div>
      </section>
    </div>;
};