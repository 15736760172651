export function checkOk(val: any, msg?: string): asserts val {
    if (!val) {
      // const error = new Error()
      // console.error(error.stack)
      throw new Error(`${msg || `expected truthy value but got ${val}`}`)
    }
  }
  
  export function checkNotNullable<T>(val: T | null | undefined, msg?: string): T {
    if (val === null || val === undefined) {
      throw new Error(`${msg || `expected non nullable value but got ${val}`}`)
    }
    return val
  }
  